<template>
  <div class="row">
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
      <!-- :scroll="{ y: 300 }" -->
      <a-table
        :columns="columns"
        :data-source="pagesList"
        size="small"
        :pagination="{
          defaultPageSize: 10000,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '25', '50', '100', '200'],
          hideOnSinglePage: true,
        }"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <a href="javascript:void(0)" class="text-primary" @click="goto(record)">{{
              record.caption !== '' && record.caption !== null ? record.caption : record.title
            }}</a>
          </template>
        </template>
      </a-table>
    </div>
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
      <a-table
        :columns="columns"
        :data-source="pagesList1"
        size="small"
        :pagination="{
          defaultPageSize: 10000,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '25', '50', '100', '200'],
          hideOnSinglePage: true,
        }"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <a href="javascript:void(0)" class="text-primary" @click="goto(record)">{{
              record.caption !== '' && record.caption !== null ? record.caption : record.title
            }}</a>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  created () {
    // var permissions = this.user.permissions

    try {
      var permissions = JSON.parse(localStorage.getItem('permissions' + this.settings.whatapp.appName.toLowerCase() + this.user.showheader.toString()))
    } catch (e) {
      console.log('e', e)
    }
    setTimeout(async () => {
      if (permissions !== undefined && permissions !== null) {
        console.log('permissions', permissions)
        // var ndatap = permissions.findIndex(x => x.key === this.settings.whatapp + '-all-form')
        // if (ndatap >= 0) {
        this.rawMenuData = permissions
        // } else {
        //   this.rawMenuData = []
        // }
        this.pushMenu()
      } else {
        var resp = await lou.customUrlGet('auth/permissions', false, true)
        if (resp) {
          localStorage.setItem('permissions' + this.settings.whatapp.appName.toLowerCase() + this.user.showheader.toString(), JSON.stringify(resp.data))
        }
        this.rawMenuData = resp.data
      }
    }, 100)
  },
  data() {
    return {
      searchText: '',
      columns: [
        {
          title: 'Menu',
          dataIndex: 'title',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.title.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          sorter: (a, b) => a.title.length - b.title.length,
        },
        // {
        //   title: 'Title',
        //   dataIndex: 'title',
        //   scopedSlots: {
        //     filterDropdown: 'filterDropdown',
        //     filterIcon: 'filterIcon',
        //     customRender: 'customRender',
        //   },
        //   onFilter: (value, record) =>
        //     record.title.toString().toLowerCase().includes(value.toLowerCase()),
        //   onFilterDropdownVisibleChange: (visible) => {
        //     if (visible) {
        //       setTimeout(() => {
        //         this.searchInput.focus()
        //       }, 0)
        //     }
        //   },
        //   sorter: (a, b) => a.title.length - b.title.length,
        // },
      ],
      datatable: [],
      // datapermissions: [],
      pagesList: null,
      pagesList1: null,
      rawMenuData: [],
    }
  },
  methods: {
    filterPageList(array) {
      var ret = []
      // console.log('array', array)
      if (array.length !== 0 || array !== null || array !== undefined) {
        array.forEach(element => {
          var exclude = ['dashboard', 'Dashboard']
          var targetExc = exclude.some(el => element.key.toString().includes(el))
          if (!targetExc) {
            ret.push(element)
          }
        })
      }
      return ret
    },
    goto(record) {
      // console.log('record', record)
      this.$router.push({ path: record.url })
    },
    pushMenu() {
      var oldData = {}
      // var menuDouble = 0
      var ndata = []
      for (let i = 0; i < this.rawMenuData.length; i++) {
        const element = this.rawMenuData[i]
        if (element === oldData) {
          // menuDouble += 1
        } else {
          ndata.push(element)
        }
        oldData = element
      }
      // console.log('Menu Double: ', menuDouble)
      this.menuData = ndata

      this.loadPagesList()
      // this.filterPagesList()
      this.componentKey += 1
    },
    loadPagesList() {
      const pagesList = () => {
        // console.log('this.menuData', this.menuData)
        const menuData = this.menuData
        const _menuData = JSON.parse(JSON.stringify(menuData))
        const flattenItems = (items, key) =>
          items.reduce((flattenedItems, item) => {
            if (item.category) {
              return flattenedItems
            }
            if (item.key === 'nestedItem1' || item.disabled) {
              // skip unwanted items
              return flattenedItems
            }
            if (item[key].length !== 0) {
              const items = item[key].map((child) => {
                child.icon = item.icon
                return child
              })
              return flattenedItems.concat(flattenItems(items, key))
            }
            delete item.children
            flattenedItems.push(item)
            return flattenedItems
          }, [])
        return flattenItems(_menuData, 'children')
      }
      var pagelist = pagesList()
      // console.log('pagelist.length', pagelist)
      var filteredPageList = this.filterPageList(pagelist)
      // console.log('filteredPageList', filteredPageList)
      var chunk = filteredPageList.length / 2
      this.pagesList = filteredPageList.slice(0, chunk)
      this.pagesList1 = filteredPageList.slice(chunk, filteredPageList.length)
      console.log('this.pagesList', this.pagesList)
      console.log('this.menuData', this.menuData)
    },
    // filterPagesList() {
    //   const pagesList = this.pagesList
    //   // const favs = this.favs
    //   // const _searchText = this.searchText ? this.searchText.toUpperCase() : ''
    //   // console.log('_searchText', _searchText)
    //   const getFilteredPageList = () => {
    //     const list = []
    //     pagesList.forEach((item) => {
    //       // const isActive = favs.some((child) => child.url === item.url)
    //       // if (!item.title.toUpperCase().includes(_searchText) && _searchText) {
    //       //   return null
    //       // }
    //       // item.isActive = isActive
    //       list.push(item)
    //       return null
    //     })
    //     return list
    //   }
    //   this.filteredPagesList = getFilteredPageList()
    // },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style></style>
